import { fetchGet} from '../../router/https'

// 城市id
function getCitiesId(data){
    return fetchGet("/mallapp/juhe/springTravel/citys", data)
}

// 风险地区
function getDangerArea(data){
    return fetchGet("/mallapp/juhe/springTravel/risk",  data)
}

// 根据城市查出入政策
function getPreventIdea(data){
    return fetchGet("/mallapp/juhe/springTravel/query",data)
}

// 核酸机构
function getHospitalList(data){
    return fetchGet("/mallapp/juhe/springTravel/hsjg", data)
}


export {
    getPreventIdea ,
    getCitiesId,
    getHospitalList,
    getDangerArea,
} 