<template>
  <div class="container" style="height: 100%">
      <van-popup v-model="showNoContent">内容</van-popup>
      <div class="top">
        <van-icon @click="goBack" name="cross" size="18" color="#fff" />
        <span style="color: #fff">各地出行防疫政策查询</span>
      </div>
      <div class="img-area">
        <img src="../../assets/fangyi.png" />
        <div class="tips"> 
          <van-icon color="#FB2B53" name="volume-o" />
          <span>出行请提前了解当前防控要求并做好个人防护</span>
        </div>
      </div>
      <div class="content">
        <div class="tab">
          <van-tabs v-model="active" @change="changeTab">
            <van-tab title="出行政策">
              <div class="tab1">
                <div class="place-title">
                  <div class="start-place">
                    <span style="margin-right: 3px" @click="isShowAddress(1)">{{
                      addressValue1
                    }}</span>
                    <img
                      style="width: 18px; height: 18px"
                      src="../../assets/箭头.png"
                    />
                  </div>
                  <div class="icon-place">
                    <img
                      style="width: 50px; height: 30px"
                      src="../../assets/双向箭头.png"
                    />
                  </div>
                  <div class="end-place">
                    <span style="margin-right: 3px" @click="isShowAddress(2)">{{
                      addressValue2
                    }}</span>
                    <img
                      style="width: 18px; height: 18px"
                      src="../../assets/箭头.png"
                    />
                  </div>
                </div>
                <div class="healthy-code">
                  <van-icon name="location" color="#fb2b53" />
                  <span style="margin: 0 5px">{{cityInfo.from_info.city_name}}</span>
                  <div class="low-div"><span>低风险</span></div>
                  <van-button 
                    size="mini" 
                    round 
                    type="primary"
                    @click="showCode">出发地{{cityInfo.from_info.health_code_name}}</van-button>
                </div>
                <div class="go-out">
                  <span class="go-out-title">外出政策</span>
                  <div class="go-out-content"></div>
                  <span>{{
                    cityInfo ? cityInfo.from_info.out_desc : ""
                  }}</span>
                </div>
                <div class="go-out" style="margin-top: 15px">
                  <span class="go-out-title">进入政策</span>
                  <div class="go-out-content"></div>
                  <span>{{
                    cityInfo ? cityInfo.to_info.out_desc : ""
                  }}</span>
                </div>
                <div class="line2"></div>
                <div class="in-desc">
                  以上政策整理自当地政府等公开发布信息，如有更新或错漏，请以最新政策为准，建议在出行前咨询当地防疫部门、机场、火车站等
                </div>
                <div class="in-out">请准备好进出凭证</div>
                <div class="last-content" @click="showCode">
                  <img
                    style="width: 16px; height: 16px"
                    src="../../assets/爱心.png"
                  />
                  <span class="your-place">{{ addressValue1 }}</span>
                  <span>{{cityInfo.from_info.health_code_name}}</span>
                  <van-icon name="arrow" />
                </div>
                <div class="last-word">
                  <span
                    >以上政策仅供参考，可能存在更新延迟或错误，请以相关部门最新通知为准</span
                  >
                </div>
              </div>
            </van-tab>
            <van-tab title="风险地区" style="height: 100%">
              <div class="tab2">
                <div class="danger-area">
                  <span class="title"
                    >截止&nbsp;{{ dangerArea.updated_date }}，&nbsp;全国疫情：</span
                  >
                  <div class="top-line"></div>
                  <div class="line-center">
                    <div class="center-div div1">
                      <span style="margin-bottom: 5px; color: #fb2b53"
                        >{{  dangerArea.high_count ? dangerArea.high_count : null
                        }}<span style="color: black">个</span></span
                      >
                      <span>高风险等级地区</span>
                    </div>
                    <div
                      class="center-div"
                      style="width: 1px; background-color: #e7e6e6"
                    ></div>
                    <div class="center-div div2">
                      <span style="margin-bottom: 5px; color: orange"
                        >{{ dangerArea.middle_count
                        }}<span style="color: black">个</span></span
                      >
                      <span>中风险等级地区</span>
                    </div>
                  </div>
                  <div class="top-line"></div>
                  <div class="word-desc">
                    <span class="word1">其余未列出地区均为低风险。</span>
                    <span
                      >由国家卫生健康委每日汇总各地报送疫情风险等级数据。</span
                    >
                  </div>
                </div>
                <div class="high-danger">
                  <div class="high-title">
                    <div></div>
                    <span>高风险地区</span>
                  </div>
                  <div
                    class="high-content"
                    v-for="(item, index) in dangerArea.high_list"
                    :key="index"
                  >
                    <div class="all-place">
                      <div class="place">
                        <span style="margin-left: 8px"
                          >{{ item.province
                          }}<span style="margin: 0 8px">{{ item.city }}</span
                          >{{ item.county }}</span
                        >
                      </div>
                      <div style="margin-left: 8px" class="center"></div>
                      <div style="margin-left: 8px" class="bottom">
                        <span v-if="item.communitys">{{ item.communitys[0] }}</span>
                        <span style="margin: 15px; color: #fb2b53;" class="bottom-span"
                          >高风险</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="center-danger">
                  <div class="center-title">
                    <div></div>
                    <span>中风险地区</span>
                  </div>
                  <div
                    class="high-content"
                    v-for="(item, index) in dangerArea.middle_list"
                    :key="index"
                  >
                    <div class="all-place">
                      <div class="place">
                        <span style="margin-left: 8px"
                          >{{ item.province
                          }}<span style="margin: 0 8px">{{ item.city }}</span
                          >{{ item.county }}</span
                        >
                      </div>
                      <div style="margin-left: 8px" class="center"></div>
                      <div style="margin-left: 8px" class="bottom">
                        <span v-if="item.communitys" style="display: inline-block; width: 80%">{{
                          item.communitys[0]
                        }}</span>
                        <span style="margin-right: 15px; color: orange;"
                          >中风险</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="low-danger" style="display: none">
                  <div class="low-title">
                    <div></div>
                    <span>低风险地区</span>
                  </div>
                  <div class="high-content">
                    <div class="all-place">
                      <div class="place">
                        <span style="margin-left: 8px"
                          >安徽省<span style="margin: 0 8px">合肥市</span
                          >蜀山区</span
                        >
                      </div>
                      <div style="margin-left: 8px" class="center"></div>
                      <div style="margin-left: 8px" class="bottom">
                        <span>尚泽街社区</span>
                        <span style="margin-right: 15px; color: green"
                          >低风险</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="high-content">
                    <div class="all-place">
                      <div class="place">
                        <span style="margin-left: 8px"
                          >安徽省<span style="margin: 0 8px">合肥市</span
                          >高新区</span
                        >
                      </div>
                      <div style="margin-left: 8px" class="center"></div>
                      <div style="margin-left: 8px" class="bottom">
                        <span>高新街社区</span>
                        <span style="margin-right: 15px; color: green"
                          >低风险</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="last-word">
                  <span
                    >由国家卫生健康委每日汇总各地报送疫情风险等级数据。</span
                  >
                </div>
              </div>
            </van-tab>
            <van-tab title="核酸检测机构">
              <div class="tab3">
                <div class="tab-title">
                  <span class="title1">{{ valueP.substr(0, 2) }}</span>
                  <span>{{ value.substr(0, 2) }}</span>
                  <img
                    @click="isShowAddress(3)"
                    style="width: 18px; height: 18px"
                    src="../../assets/箭头.png"
                  />
                  <div class="tab3-line"></div>
                  <van-field
                    v-model="selectAddress"
                    placeholder="输入地址或医院名称选择"
                  />
                </div>
                <div class="hospitals">
                  <div
                    class="hospital"
                    v-for="(item, index) in allHospital"
                    :key="index"
                  >
                    <div class="hospital-title">{{ item.name }}</div>
                    <div class="hospital-line"></div>
                    <div class="your-address">
                      <span style="padding-top: 10px">所在区县：</span
                      ><span>{{ item.province }}{{ item.city }}</span>
                    </div>
                    <div class="phone-num">
                      <span>联系电话：</span
                      ><span style="color: #23ae39">{{ item.phone }}</span>
                    </div>
                    <div class="phone-num">
                      <span>详细地址：</span><span>{{ item.address }}</span>
                    </div>
                  </div>
                </div>
                <div class="last-word">
                  <span
                    >由国家卫生健康委每日汇总各地报送疫情风险等级数据。</span
                  >
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
      <!-- 点击合肥显示 -->
      <van-action-sheet class="show-address" v-model="show">
        <van-area
          :area-list="areaList"
          :columns-num="2"
          @cancel="cancelAddress"
          @confirm="changeAddress"
        />
      </van-action-sheet>
      <!-- 弹出二维码 -->
      <van-popup v-model="showCodeTrue" round>
        <img style="" :src="cityInfo.from_info.health_code_picture"/>
      </van-popup>
  </div>
</template>

<script>
// <img src="../../assets/缺省页_暂无缴费@2x.png"/>
import { areaList } from "@vant/area-data";
// getHospitalList getDangerArea
import {
  getPreventIdea,
  getCitiesId,
  getHospitalList,
  getDangerArea,
} from "@/api/prevent";
export default {
  data() {
    return {
      addressValue1: "合肥",
      addressValue2: "芜湖", //目的地
      active: 0,
      areaList: areaList,
      show: false,
      status: null, // 控制第几个地址
      valueP: "安徽", //选中的医院省
      value: "合肥", //选中的医院市
      cityIds: null,
      selectAddress: null,
      city: {},
      city2: {},
      cityInfo: {},
      dangerArea: {},
      allHospital: null,
      reasonWhy: '',
      isNone: null,
      showNoContent: false,
      // 弹出二维码
      showCodeTrue: false 
    };
  },
  created() {
    this.$store.commit("setSession", this.$route.query.session)
    this.getCitiesId();
    this.getDangerArea();
    this.getPreventIdea();
    this.getHospitalList();
  },
  activated() {},
  methods: {
    // 点击弹出二维码
    showCode() {
      this.showCodeTrue = true
    },
    // 原生返回
    goBack() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        // close
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    // 风险地区
    getDangerArea() {
      getDangerArea().then((res) => {
        if (res.data.code === 0) {
          this.dangerArea = res.data.data.result;
          const dangerArea = res.data.data.result
          const date = dangerArea.updated_date.replace(/-/g, '/')
          const y = new Date(date).getFullYear()
          const m = (new Date(date).getMonth() + 1).toString().padStart(2, '0')
          const d = (new Date(date).getDate()).toString().padStart(2, '0')
          const time = `${y}-${m}-${d}`
          this.dangerArea.updated_date = time
          console.log(time)
        }
      });
    },
    getCitiesId() {
      getCitiesId().then((res) => {
        if (res.data.code === 0) {
          this.cityIds = res.data.data.result;
          this.reasonWhy = res.data.data.reason
        }
      });
    },
    // 取消地址
    cancelAddress() {
      this.show = false;
    },
    // 封装里面的函数 初始化调用 tab3
    getHospitalList() {
      getHospitalList({ city_id: "10001" }).then((res) => {
        if (res.data.code === 0) {
          this.allHospital = res.data.data.result.data;
        }
      });
    },
    // 封装里面的函数 初始化调用 tab1
    getPreventIdea() {
      let params = {
        from: "10001",
        to: "10002",
      };
      getPreventIdea(params).then((res) => {
        if (res.data.code === 0) {
          this.cityInfo = res.data.data.result;
          this.addressValue1 = this.cityInfo.from_info.city_name;
          this.addressValue2 = this.cityInfo.to_info.city_name;
        }
      });
    },
    // 地址的改变
    changeAddress(val) {
      this.show = false;
      if (this.status == 1) {
        this.addressValue1 = val[1].name;
        const cityId = JSON.parse(JSON.stringify(this.cityIds));
        cityId.forEach((x) => {
          x.citys.forEach((c) => {
            c.city = c.city + "市";
            if (c.city == this.addressValue1) {
              this.city = JSON.parse(JSON.stringify(c));
            }
          });
        });
      } else if (this.status == 2) {
        this.addressValue2 = val[1].name;
        const cityId = JSON.parse(JSON.stringify(this.cityIds));
        cityId.forEach((x) => {
          x.citys.forEach((c) => {
            c.city = c.city + "市";
            if (c.city == this.addressValue2) {
              this.city2 = JSON.parse(JSON.stringify(c));
            }
          });
        });
      } else {
        //3
        // 核酸机构
        this.value = val[1].name;
        this.valueP = val[0].name;
        let params = {
          city_id: null,
        };
        const cityId = JSON.parse(JSON.stringify(this.cityIds));
        cityId.forEach((x) => {
          x.citys.forEach((c) => {
            c.city = c.city + "市";
            if (c.city == this.value) {
              params.city_id = c.city_id;
            }
          });
        });
        getHospitalList(params).then((res) => {
          if (res.data.code === 0) {
            this.allHospital = res.data.data.result.data;
          }
        });
      }
      let params = {
        from: this.city.city_id || "10001",
        to: this.city2.city_id || "10002",
      };
      getPreventIdea(params).then((res) => {
        if (res.data.code === 0) {
          this.cityInfo = res.data.data.result;
          this.addressValue1 = this.cityInfo.from_info.city_name;
          this.addressValue2 = this.cityInfo.to_info.city_name;
        }
      });
    },
    // 显示地址
    isShowAddress(status) {
      this.status = status;
      this.show = !this.show;
    },
    changeTab() {
    },

    // tab3
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}

.container {
  background-color: #f6f6f6;
  overflow-x: hidden;
  .top {
    width: 100%;
    height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    background-color: #fb2b53;
    display: flex;
    align-items: center;
    .van-icon {
      margin: 0 50px 0 15px;
    }
  }
  .img-area {
    width: 100%;
    height: 180px;
    margin-top: 50px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .tips {
      position: absolute;
      top: 129px;
      left: 17px;
      width: 90%;
      height: 40px;
      //   border: 1px solid red;
      background-color: #fff;
      border-radius: 8px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .van-icon {
        margin: 0 16px 0 10px;
      }
      span {
        color: #fb2b53;
      }
    }
  }
  .content {
    background-color: #fff;
    // height: 100%;
    margin: 0 15px;
    margin-top: 35px;
    border-radius: 8px;
    .tab {
      .tab1 {
        .place-title {
          height: 60px;
          width: 100%;
          background-color: #f6f6f6;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .start-place {
            display: flex;
            align-items: center;
            justify-content: space-around;
          }
          .end-place {
            display: flex;
            align-items: center;
            justify-content: space-around;
          }
        }
        .healthy-code {
          padding: 20px 0;
          height: 30px;
          display: flex;
          align-items: center;
          .low-div {
            flex: 1;
            color: #07c160;
          }
        }
        .go-out {
          margin-left: 20px;
          .go-out-title {
            font-size: 16px;
            font-weight: 500;
          }
          .go-out-content {
            margin-top: 10px;
          }
        }
        .line2 {
          margin: 15px 0 15px 20px;
          width: 91%;
          height: 1px;
          background-color: #999999;
        }
        .in-desc {
          margin-left: 20px;
          margin-right: 5px;
          color: #999999;
        }
        .in-out {
          font-size: 16px;
          margin-left: 20px;
          margin: 15px 20px;
          // padding-bottom: 15px;
        }
        .last-content {
          width: 90%;
          height: 40px;
          margin-left: 20px;
          border-radius: 10px;
          background-color: #f6f6f6;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img {
            margin: 0 10px;
          }
          .your-place {
            flex: 1;
          }
          .van-icon {
            margin: 0 10px;
          }
        }
        .last-word {
          background-color: #f6f6f6;
          width: 110%;
          height: 70px;
          margin-left: -8px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin-left: 10px;
            margin-right: 15px;
            color: #cecbcb;
          }
        }
      }
      .tab2 {
        //    风险地区
        .danger-area {
          box-shadow: 0px 0px 10px #f5f3f3;
          // border: 1px solid pink;
          height: 270px;
          border-radius: 8px;
          //  box-shadow: 10px 10px 5px #888888;
          .title {
            display: inline-block;
            margin: 15px;
          }
          .top-line {
            margin: 0 15px;
            height: 1px;
            width: 95%;
            background-color: #e7e6e6;
          }
          .line-center {
            margin-right: 15px;
            height: 120px;
            width: 100%;
            // background-color: rgb(229, 248, 252);
            display: flex;
            justify-content: space-around;
            align-items: center;
            .center-div {
              height: 80px;
              width: 40%;
            }
            .div1 {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .div2 {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }
          .word-desc {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            margin-left: 15px;
            .word1 {
              font-weight: bold;
              margin-bottom: 5px;
            }
          }
        }
        .high-danger {
          .high-title {
            display: flex;
            align-items: center;
            margin: 15px 0px;
            div {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: #fb2b53;
              margin-right: 8px;
            }
            span {
              color: #fb2b53;
            }
          }
          .high-content {
            // height: 100px;
            padding: 5px 0;
            width: 100%;
            box-shadow: 0px 0px 10px #f5f3f3;
            // border: 1px solid pink;
            border-radius: 8px;
            margin-bottom: 20px;
            .place {
              margin: 15px 0;
            }
            .center {
              width: 98%;
              height: 1px;
              background-color: #e7e6e6;
            }
            .bottom {
              margin: 15px 0;
              display: flex;
              justify-content: space-between;
              &-span {
                flex-shrink: 0;
              }
            }
          }
        }
        .center-danger {
          .center-title {
            display: flex;
            align-items: center;
            margin: 15px 0px;
            div {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: orange;
              margin-right: 8px;
            }
            span {
              color: orange;
            }
          }
          .high-content {
            // height: 100px;
            padding: 5px 0;
            width: 100%;
            box-shadow: 0px 0px 10px #f5f3f3;
            // border: 1px solid pink;
            border-radius: 8px;
            margin-bottom: 20px;
            .place {
              margin: 15px 0;
            }
            .center {
              width: 98%;
              height: 1px;
              background-color: #e7e6e6;
            }
            .bottom {
              margin: 15px 0;
              display: flex;
              justify-content: space-between;
            }
          }
        }
        .low-danger {
          .low-title {
            display: flex;
            align-items: center;
            margin: 15px 0px;
            div {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: green;
              margin-right: 8px;
            }
            span {
              color: green;
            }
          }
          .high-content {
            // height: 100px;
            padding: 5px 0;
            width: 100%;
            box-shadow: 0px 0px 10px #f5f3f3;
            // border: 1px solid pink;
            border-radius: 8px;
            margin-bottom: 20px;
            .place {
              margin: 15px 0;
            }
            .center {
              width: 98%;
              height: 1px;
              background-color: #e7e6e6;
            }
            .bottom {
              margin: 15px 0;
              display: flex;
              justify-content: space-between;
            }
          }
        }
        .last-word {
          background-color: #f6f6f6;
          width: 110%;
          height: 70px;
          margin-left: -8px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin-left: 10px;
            margin-right: 15px;
            color: #cecbcb;
          }
        }
      }
      .tab3 {
        .tab-title {
          width: 100%;
          height: 50px;
          background-color: #f5f5f5;
          border-radius: 10px;
          display: flex;
          //  justify-content: center;
          align-items: center;
          .title1 {
            margin: 0 10px;
          }
          img {
            margin: 0 20px 0 10px;
          }
          .tab3-line {
            height: 65%;
            width: 1px;
            background-color: #999999;
            margin-right: 20px;
          }
          .van-field {
            width: 50%;
          }
        }
        .hospitals {
          margin: 15px 0;
          .hospital {
            box-shadow: 0px 0px 10px #f5f3f3;
            width: 100%;
            // height: 195px;
            padding: 5px 0;
            // border: 1px solid pink;
            border-radius: 10px;
            margin-bottom: 20px;
            .hospital-title {
              margin: 20px 0 20px 20px;
              font-size: 16px;
              color: #333333;
              font-weight: 500;
            }
            .hospital-line {
              width: 94%;
              height: 1px;
              background-color: #999999;
              margin: 15px 0 15px 20px;
            }
            .your-address {
              margin-left: 20px;
            }
            .phone-num {
              margin: 15px 0 15px 20px;
            }
          }
        }
        .last-word {
          background-color: #f6f6f6;
          width: 110%;
          height: 70px;
          margin-left: -8px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin-left: 10px;
            margin-right: 15px;
            color: #cecbcb;
          }
        }
      }
    }
  }
  .show-address {
    .btn {
      margin: 20px 0;
      margin-left: 40%;
      border-radius: 10px;
    }
  }
  // /deep/ .van-picker__toolbar {
  //   display: none;
  // }
  /deep/ .van-field__control {
    background: #f5f5f5;
  }
  /deep/ .van-cell {
    padding: 0;
  }
  /deep/ .van-button__content {
    padding: 0 15px;
  }
  /deep/.van-tab {
    font-size: 18px;
  }
  /deep/ .van-tabs__line {
    height: 5px;
    width: 80px;
    margin-left: -13px;
  }
  /deep/.van-tab__text--ellipsis {
    margin-left: -25px;
  }
  /deep/ .van-tabs__content {
    margin: 10px 8px 0;
  }
}
/deep/ .van-tabs .van-tab {
  height: 100%;
}
</style>
